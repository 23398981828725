@import "_utils";

.clients-served {
  $side-border: 1px solid $main-pink;
  background: black;
  color: white;
  height: 100%;
  margin-bottom: 50px;
  padding: 100px 20px 50px;

  @include breakpoint($desktop) {
    margin-bottom: 0;
    padding: 0 20px 110px;
  }

  h3 {
    color: white;
    font-family: $primary-font;
    font-size: 25px;
    font-weight: normal;
    letter-spacing: 1.25px;
    margin-bottom: 50px;
    text-align: center;

    @include breakpoint($desktop) {
      margin: 60px 0 50px;
      padding-top: 50px;
      font-size: 50px;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    --stagger-delay: 100ms;

    @include breakpoint($desktop) {
      display: grid;
      gap: 1rem;
      // sass-lint:disable indentation
      grid-template-areas:
        "a b b b"
        "c c d d"
        "e f f x";
      // sass-lint:enable indentation
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      height: 100vh;
      margin: 0 auto;
      max-width: 1400px;
    }
  }

  @keyframes cardEntrance {
    from {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      pointer-events: visible;
      transform: scale(1);
    }
  }

  a {
    opacity: 0;
    text-decoration: none;
    margin-bottom: 40px;
    transition: ease-in-out all 0.3s;
    transform: translateX(0);
    width: 100%;

    @include breakpoint($desktop) {
      background: white;
      position: relative;
      margin: 0;

      &:hover {
        transform: translateX(-15px);
        z-index: 1;

        .img {
          opacity: 1;
        }

        .overlay {
          box-shadow: 6px 9px 20px -11px $main-pink;
          opacity: 1;
          right: 0;
        }
      }
    }

    &.cardEntrance {
      animation: cardEntrance 700ms ease-out;
      animation-fill-mode: forwards;
    }

    &:nth-child(1) {
      grid-area: a;
      animation-delay: calc(
        1 * var(--stagger-delay)
      );
    }
    &:nth-child(2) {
      grid-area: b;
      animation-delay: calc(
        2 * var(--stagger-delay)
      );
    }
    &:nth-child(3) {
      grid-area: c;
      animation-delay: calc(
        3 * var(--stagger-delay)
      );
    }
    &:nth-child(4) {
      grid-area: d;
      animation-delay: calc(
        4 * var(--stagger-delay)
      );
    }
    &:nth-child(5) {
      grid-area: e;
      animation-delay: calc(
        5 * var(--stagger-delay)
      );
    }
    &:nth-child(6) {
      grid-area: f;
      animation-delay: calc(
        6 * var(--stagger-delay)
      );
    }
    &:last-child {
      grid-area: x;
      animation-delay: calc(
        7 * var(--stagger-delay)
      );
    }

    .img {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 200px;
      opacity: 0.8;
      width: 100%;

      @include breakpoint($desktop) {
        height: 100%;
      }
    }

    .overlay {
      background: black;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      text-align: left;

      @include breakpoint($desktop) {
        box-shadow: none;
        margin: auto;
        opacity: 0;
        padding: 0 20px;
        position: absolute;
        max-width: 300px;
        transition: 0.3s all ease-in;
        z-index: 1;

        top: 0;
        bottom: 0;
        right: -100px;
      }

      h4 {
        border-bottom: 1px dashed $main-pink;
        color: $main-pink;
        font-family: $primary-font;
        font-weight: normal;
        letter-spacing: 1.25px;
        margin-bottom: 10px;
        padding-bottom: 10px;

        @include breakpoint($desktop) {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }

      p {
        letter-spacing: 0.8px;
        line-height: 1.16667;
      }
    }
  }
}
