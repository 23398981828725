@import "_utils";

.about {
  font-family: $primary-font;
  padding: 0 24px;
  margin-top: 50px;

  @include breakpoint($desktop) {
    align-items: center;
    margin-top: 0;
    padding: 100px 20px 0 0;
  }

  .triangle {
    border-left: 600px solid transparent;
    display: none;
    height: 0;
    position: absolute;
    transform: rotate(180deg);
    width: 100%;

    @include breakpoint($desktop) {
      border-bottom: 200px solid black;
      border-right: 525px solid transparent;
      display: block;
    }
  }

  .personal-photo {
    border: 1px dashed black;
    border-bottom: 2px solid black;
    border-right: 12px solid black;
    border-radius: 50px;
    height: 410px;
    margin: auto auto 20px;
    overflow: hidden;
    position: relative;
    transition: 1s all ease;
    width: 310px;

    @include breakpoint($desktop) {
      margin: 35px auto 0;
    }

    img {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: -5px;
      width: 100%;
    }
  }

  .wrapper {
    margin: 50px 0;

    @include breakpoint($desktop) {
      display: flex;
      justify-content: center;
      margin: 75px auto 100px;
      max-width: 1400px;
    }
  }

  p {
    font-family: $secondary-font;
    font-style: italic;
    margin-bottom: 50px;
    text-align: center;

    @include breakpoint($desktop) {
      flex-basis: 45%;
      font-size: 25px;
      margin: auto 50px auto 0;
      max-width: 750px;
      text-align: left;
    }

    .txt-decoration {
      font-family: $primary-font;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  @keyframes animatedWidth {
    from {
      opacity: 0;
      width: 0%;
    }

    to {
      opacity: 1;
      width: 100%;
    }
  }

  .tech-skills {
    width: 100%;

    @include breakpoint($desktop) {
      flex-basis: 33%;
    }

    &.fadeIn {
      .bar {
        animation: animatedWidth 1s ease-in-out;
        animation-fill-mode: forwards;
      }
    }

    h2 {
      display: none;
      margin-bottom: 30px;
      text-align: center;
    }

    .bar {
      --stagger-delay: 100ms;
      --top-percent: 100;
      height: 35px;
      margin-bottom: 5px;
      position: relative;
      width: 100%;

      @include breakpoint($desktop) {
        max-width: 500px;
      }

      &:nth-of-type(1) {
        animation-delay: calc(1 * var(--stagger-delay));

        span {
          width: 100%;
        }
      }

      &:nth-of-type(2) {
        animation-delay: calc(2 * var(--stagger-delay));

        span {
          width: 100%;
        }
      }

      &:nth-of-type(3) {
        animation-delay: calc(3 * var(--stagger-delay));

        span {
          width: 100%;
        }
      }

      &:nth-of-type(4) {
        animation-delay: calc(4 * var(--stagger-delay));

        span {
          width: 70%;
        }
      }

      &:nth-of-type(5) {
        animation-delay: calc(5 * var(--stagger-delay));

        span {
          width: 70%;
        }
      }

      &:nth-of-type(6) {
        animation-delay: calc(6 * var(--stagger-delay));

        span {
          width: 70%;
        }
      }

      &:nth-of-type(7) {
        animation-delay: calc(7 * var(--stagger-delay));

        span {
          width: 40%;
        }
      }

      &:nth-of-type(8) {
        animation-delay: calc(8 * var(--stagger-delay));

        span {
          width: 40%;
        }
      }
    }

    span {
      background: black;
      border-bottom: 3px solid $main-pink;
      color: white;
      height: 100%;
      letter-spacing: 2px;
      overflow: hidden;
      padding: 8px 10px;
      position: absolute;
      width: 75%;
      transition: width 0.5s ease;
    }
  }
}
