@import "_utils";

.navBar {
  animation: slideIn 1s forwards;
  animation-delay: 1s;
  background: $main-black;
  bottom: 10px;
  color: white;
  justify-content: space-between;
  opacity: 0;
  position: absolute;
  text-align: left;
  transition: all 0.5s ease-in-out;
  width: 55%;
  z-index: 100000;

  @include breakpoint($desktop) {
    bottom: 0;
    display: flex;
    width: 250px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.expand {
    padding: 0 20px;
    width: 100%;

    @include breakpoint($desktop) {
      padding: 0 150px;
    }

    ul {
      @include breakpoint($desktop) {
        display: flex;
        opacity: 1;
      }
    }
  }

  &.fixed {
    box-shadow: 0px 1px 6px $main-pink;
    height: 90px;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    width: 100%;

    @include breakpoint($desktop) {
      height: 100px;
    }
  }

  @keyframes slideIn {
    0% {
      right: -100px;
      opacity: 0;
    }

    100% {
      right: 0;
      opacity: 1;
    }
  }

  .info {
    height: 100%;
    overflow: hidden;
    padding: 10px 20px;

    @include breakpoint($desktop) {
      display: block;
      padding: 20px;
    }
  }

  h1 {
    font-size: 30px;
    font-weight: 100;
  }

  p {
    color: $main-pink;
    font-style: italic;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 5px;
    text-transform: uppercase;
  }

  ul {
    align-items: center;
    display: flex;
    display: none;
    height: 100%;
    opacity: 0;
    font-family: $primary-font;
    transition: opacity 10s ease;

    li {
      $selected-elem: 3px solid $main-pink;
      border-bottom: 0;
      cursor: pointer;
      font-size: 20px;
      list-style-type: none;
      margin-right: 50px;
      padding-bottom: 5px;
      transition: border 100ms ease-in;

      &:hover {
        border-bottom: $selected-elem;
      }

      &.selected {
        border-bottom: $selected-elem;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
