@import "_utils";

.contact-form {
  color: white;
  font-family: $secondary-font;
  font-style: italic;

  .wrapper {
    background: black;
    border-bottom: 5px solid $main-pink;
    border-radius: 10px;
    margin: 24px;
    padding: 50px 24px;
    transform: scale(0.8);
    transition: transform 500ms ease-in-out;

    @include breakpoint($desktop) {
      border-radius: 20px;
      margin: 200px auto 50px;
      max-width: 800px;
    }

    &.scaleAnim {
      transform: scale(1);
    }
  }

  form {
    margin: auto;
    max-width: 500px;

    div {
      border-bottom: 2px dashed white;
      padding-top: 30px;
      overflow-y: hidden;
      position: relative;
      transition: 500ms;

      &:after {
        content: "";
        position: relative;
        display: block;
        height: 4px;
        width: 100%;
        background: $main-pink;
        transform: scaleX(0);
        transform-origin: 0%;
        opacity: 0;
        transition: all 500ms ease;
        top: 2px;
      }

      &:focus {
        border: none;
      }

      &:focus-within {
        border-color: transparent;
      }

      &:focus-within label,
      input:not(:placeholder-shown) + label,
      textarea:not(:placeholder-shown) + label {
        transform: scale(0.8) translateY(-2.5rem);
        opacity: 1;
      }

      &:focus-within::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }

  .header {
    margin-bottom: 50px;

    h3 {
      font-family: $primary-font;
      font-size: 25px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.8px;
      margin-bottom: 20px;
      text-align: center;

      @include breakpoint($desktop) {
        font-size: 50px;
      }
    }

    p {
      color: $main-pink;
      font-size: 20px;
      letter-spacing: 1px;
      margin: auto;
      text-align: center;
    }
  }

  div {
    margin-bottom: 25px;
  }

  textarea,
  input {
    background: black;
    border: none;
    color: $main-pink;
    font-size: 15px;
    height: 35px;
    overflow: hidden;
    padding: 10px 2px;
    margin-top: 5px;
    transition: border 500ms;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:valid {
      color: white;
    }

    &:invalid {
      color: orangered;
    }
  }

  label {
    bottom: 10px;
    font-size: 18px;
    letter-spacing: 0.8px;
    left: 0;
    position: absolute;
    pointer-events: none;
    transform: translateY(0rem);
    transform-origin: 0%;
    transition: transform 400ms;
  }

  button {
    background: black;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    margin: auto;
    padding: 10px;
    text-align: center;
    transition: 0.3s background ease-in;
    width: auto;

    &:hover {
      background: $main-pink;
    }

    @include breakpoint($desktop) {
      justify-content: center;
      padding: 20px;
      width: 200px;
    }
  }
}
