$primary-font: "Work Sans", sans-serif;
$secondary-font: "Open Sans", sans-serif;
$standard-margin: 50px 20px;
$desktop: 960px;

$main-pink: #e31b6d;
$main-black: black;

@mixin section-headers {
  font-family: $primary-font;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

@mixin breakpoint($measure) {
  @media only screen and (min-width: $measure) {
    @content;
  }
}
