@import "_utils";

.hero {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 100%;

  @include breakpoint($desktop) {
    height: 100vh;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.1;
      transform: translateY(-50%);
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  canvas {
    animation: fadeOut 3s forwards;
    //animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    opacity: 0;
    z-index: 10000;
  }

  .painting {
    animation: fadeIn 5s forwards;
    //animation-delay: 2s;
    background-image: url("img/kandinsky-bckgrd.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @include breakpoint($desktop) {
      background-size: cover;
    }
  }

  @keyframes slider {
    0% {
      border-left: 0;
      right: 0;
    }
    100% {
      border-left: 5px solid $main-pink;
      left: 50%;
      width: 30%;
    }
  }

  @keyframes slide-in {
    0% {
      left: 0;
      width: 0%;
    }

    100% {
      width: 20%;
    }
  }
}
