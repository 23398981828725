@import "_utils";

.skill-set {
  color: $main-black;

  .container {
    padding: 50px 24px 0;
    border-bottom: 24px solid $main-black;
    border-left: 16px solid $main-black;
    border-right: 16px solid $main-black;
    width: 100%;

    @include breakpoint($desktop) {
      border: 0;
      margin: 50px 0 100px;
      padding: 50px;
    }
  }

  h2 {
    @include section-headers();
    margin-bottom: 50px;
    font-size: 50px;
    text-decoration: none;

    @include breakpoint($desktop) {
      margin-bottom: 60px;
    }

    a {
      color: #551a8b;
      text-decoration: none;
    }

    .underline--magical {
      background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.2em;
      background-position: 0 105%;
      transition: background-size 0.25s ease-in;

      &:hover {
        background-size: 100% 88%;
        color: blue;
      }
    }
  }

  ul {
    font-family: $secondary-font;
    font-style: italic;
    margin: 30px auto 0;
    padding: 0;

    @include breakpoint($desktop) {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-basis: 1;
      height: 100%;
      margin: 0 auto;
      max-width: 1150px;
      white-space: normal;
    }
  }

  li {
    list-style-type: none;
    margin-bottom: 50px;

    @include breakpoint($desktop) {
      margin: 0;
      width: 33%;

      &:not(:last-child) {
        margin: 0 30px 0 0;
      }
    }

    p {
      font-size: 20px;
      font-weight: bold;
      height: 100%;
      margin: auto;
      overflow-y: hidden;
      padding: 15px 25px 10px;
      position: relative;
      text-align: center;
      width: 250px;

      @include breakpoint($desktop) {
        margin: 30px auto 0;
      }

      &:before,
      &:after {
        font-family: $primary-font;
        font-style: normal;
        position: absolute;
        top: 0;
        font-size: 65px;
      }

      &:before {
        content: "{";
        left: 0;
      }

      &:after {
        content: "}";
        right: 0;
      }

      @include breakpoint($desktop) {
        border: 0;
      }
    }
  }

  .art-container {
    height: 300px;
    margin: 0 auto 30px;
    max-width: 400px;
    position: relative;
    border: 5px solid black;
    box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.3);
    width: auto;

    @include breakpoint($desktop) {
      margin: 0 auto;
      max-width: 300px;
    }
  }

  .art {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) inset;
    display: flex;
    height: 100%;
    margin: auto;
    width: 100%;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) inset;
    }

    img {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 150px;
    }
  }
}
