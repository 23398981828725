@import "_utils";
footer {
  margin: 25px 0 30px;

  @include breakpoint($desktop) {
    margin: 50px 0 100px;
  }

  ul {
    display: flex;
    justify-content: center;
    height: 100%;
    list-style-type: none;
    overflow-y: hidden;

    li {
      margin-right: 10px;
      padding: 5px;
      transition: transform 300ms ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      @include breakpoint($desktop) {
        margin-right: 10px;
      }
    }

    img {
      height: 35px;
      width: 35px;

      @include breakpoint($desktop) {
        height: 50px;
        width: 50px;
      }
    }
  }
}
